export default (data = {}) => {
    return {
        type_organization: null, 
        type_regime: null, 
        type_liability: null, 
        municipality: null,
        type_document_identifications: null,
        url: null,
        url_qr: null,
        cufe_algorithm: null,
        type: null,
        prefix: null,
        ...data
    }
}